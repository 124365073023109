@use "../../scss/variables" as vars;

.closeButton {
  height: 30px;
  width: 30px;
  background-color: vars.$color-danger;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.closeButton:hover {
  opacity: 0.7;
}

.videoSection {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  //   border-radius: 15px;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
  }
}

.rounded {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  //   background-color: red;
  //   border: 1px solid red;
}

.addButtonGreen {
  background-color: #444d52;
  //   border: 3px solid black;
  height: 48px;
  border-radius: 20px;
  color: white;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}
.closeBtn {
  background-color: #3d4447;
//   border: 3px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  color: white;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}
