@use "../../scss/variables" as vars;

.closeButton {
  height: 30px;
  width: 30px;
  background-color: vars.$color-danger;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.closeButton:hover {
  opacity: 0.7;
}

.rounded {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  //   background-color: red;
  // border: 1px solid red;
}

.closeBtn {
  background-color: #3d4447;
  //   border: 3px solid black;
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  color: white;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}

input {
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  height: 35px;
  padding: 2rem;
  font-size: 1.4rem;
  ::placeholder {
    color: vars.$color-dark_grey_50;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //   border:1px solid green;
  height: 30px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  // border:1px solid red;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #b2fc02;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 10px;
  top: 2px;
  width: 10px;
  height: 20px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sendButton {
  height: 55px;
  border-radius: 30px;
  width: 100%;
  border: 0px;
  background-color: #b2fc02;
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: black;
  &:hover {
    background-color: vars.$color-dark_grey_50;
  }
}
